import React, { useEffect, useState } from "react";
import { FiArrowRight } from "react-icons/fi";
import {
    useMotionTemplate,
    useMotionValue,
    motion,
    animate,
} from "framer-motion";

import step1 from "./step1.jpg";
import step2 from "./step2.jpg";
import step3 from "./step3.jpg";
import step4 from "./step4.jpg";
import step5 from "./step5.jpg";

import Img1 from "./img1.jpg";
import Img2 from "./img2.jpg";
import Img3 from "./img3.jpg";
import Img4 from "./img4.jpg";
import Img5 from "./img5.jpg";


import Logo from "./logo.png";
import a3 from "./a3.png";
import a2 from "./a2.png";
import a1 from "./a1.png";
const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];
export default function Home(params) {
    const [isOpen, setIsOpen] = useState(false);
    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    const [isOpen2, setIsOpen2] = useState(false);

    const openModal2 = () => setIsOpen2(true);
    const closeModal2 = () => setIsOpen2(false);

    const color = useMotionValue(COLORS_TOP[0]);

    useEffect(() => {
        animate(color, COLORS_TOP, {
            ease: "easeInOut",
            duration: 10,
            repeat: Infinity,
            repeatType: "mirror",
        });
    }, []);

    const border = useMotionTemplate`1px solid ${color}`;
    const boxShadow = useMotionTemplate`0px 4px 24px ${color}`;
    return (
        <>

            <div class=" mx-auto px-4  sm:max-w-xl md:max-w-full md:px-8 lg:py-16 xl:px-20 overflow-hidden">
                <div class="mx-auto max-w-xl lg:max-w-screen-xl ">
                    <div class="mb-16 lg:mb-0 lg:max-w-lg ">
                        <div class="mb-6 max-w-xl ">
                            <div>
                                <p class="bg-teal-accent-400 mb-4 inline-block rounded-full px-3 py-px text-xs font-semibold uppercase tracking-wider text-cyan-900 ">Tech Saksham</p>
                            </div>
                            <h2 class="mb-6 max-w-lg font-sans text-xl font-bold tracking-tight text-slate-700 sm:text-3xl sm:leading-snug ">
                                "Exceptional Software Development to Accelerate Your Business Growth"
                            </h2>
                            <h1 class="inline-block text-cyan-500 mb-4 ">Tech Saksham Best Software Company</h1>
                            <p class="text-base text-gray-700 md:text-lg ">"At <span className="text-cyan-500 font-semibold">Tech Saksham</span> , we craft innovative software solutions that drive business success. Our expertise and cutting-edge technology turn your ideas into high-performance applications tailored to your needs."</p>
                        </div>
                        <div class="flex items-center ">
                            <a href="/" class="mr-6 inline-flex h-12 items-center justify-center rounded bg-cyan-500 px-6 font-medium tracking-wide text-white shadow-md outline-none transition duration-200 hover:bg-cyan-400 focus:ring"> Get started </a>
                        </div>
                    </div>
                </div>
                <div class="flex h-full justify-center overflow-hidden lg:absolute lg:bottom-0 lg:right-0 lg:w-2/3 lg:items-center lg:justify-start xl:w-1/2">
                    <img src={a2} class="-mb-16 h-80 w-full max-w-xl rounded-2xl object-cover object-top shadow-2xl shadow-cyan-200 lg:ml-64 lg:-mb-24 lg:h-auto lg:max-w-screen-md xl:ml-8 xl:-mb-28" alt="" />
                </div>
            </div>


            <section class="py-15">
                <h1 class="mb-12 ps-10 text-left font-sans text-5xl text-cyan-500 font-semibold">Service We Offer</h1>
                <div class="mx-auto grid max-w-screen-lg grid-cols-1 gap-5 p-5 sm:grid-cols-2 md:grid-cols-3 lg:gap-10">

                    <article class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5">
                        <a href="#" class="block h-full w-full">
                            <img class="max-h-40 w-full object-cover" alt="featured image" src={Img3} />
                            <div class="w-full bg-white p-4">
                                <p class="text-md font-medium text-cyan-500">4.8 Rating</p>
                                <p class="mb-2 text-xl font-medium text-gray-800">App Development</p>
                                <p class="text-md font-light text-gray-400">"Creating seamless, high-performance mobile apps for business success."</p>
                                <div class="justify-starts mt-4 flex flex-wrap items-center">
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">Kotlin</div>
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">React Native</div>
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">Java </div>
                                </div>
                            </div>
                        </a>
                    </article>

                    <article class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5">
                        <a href="#" class="block h-full w-full">
                            <img class="max-h-40 w-full object-cover" alt="featured image" src={Img5} />
                            <div class="w-full bg-white p-4">
                                <p class="text-md font-medium text-cyan-500">4.8 Rating</p>
                                <p class="mb-2 text-xl font-medium text-gray-800">Web Development</p>
                                <p class="text-md font-light text-gray-400">"Building responsive, secure websites tailored to your business needs."</p>
                                <div class="justify-starts mt-4 flex flex-wrap items-center">
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">Kotlin</div>
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">React Native</div>
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">Js</div>

                                </div>
                            </div>
                        </a>
                    </article>

                    <article class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5">
                        <a href="#" class="block h-full w-full">
                            <img class="max-h-40 w-full object-cover" alt="featured image" src={Img4} />
                            <div class="w-full bg-white p-4">
                                <p class="text-md font-medium text-cyan-500">4.8 Rating</p>
                                <p class="mb-2 text-xl font-medium text-gray-800">Software Development</p>
                                <p class="text-md font-light text-gray-400">"Developing scalable, efficient software solutions to drive business growth."</p>
                                <div class="justify-starts mt-4 flex flex-wrap items-center">
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">Kotlin</div>
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">React Native</div>
                                </div>
                            </div>
                        </a>
                    </article>
                    <article class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5">
                        <a href="#" class="block h-full w-full">
                            <img class="max-h-40 w-full object-cover" alt="featured image" src={Img1} />
                            <div class="w-full bg-white p-4">
                                <p class="text-md font-medium text-cyan-500">4.8 Rating</p>
                                <p class="mb-2 text-xl font-medium text-gray-800">UI-UX Design</p>
                                <p class="text-md font-light text-gray-400">"Crafting intuitive, user-friendly designs for seamless digital experiences."</p>
                                <div class="justify-starts mt-4 flex flex-wrap items-center">
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">Kotlin</div>
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">React Native</div>
                                </div>
                            </div>
                        </a>
                    </article>
                    <article class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5">
                        <a href="#" class="block h-full w-full">
                            <img class="max-h-40 w-full object-cover" alt="featured image" src={Img3} />
                            <div class="w-full bg-white p-4">
                                <p class="text-md font-medium text-cyan-500">4.8 Rating</p>
                                <p class="mb-2 text-xl font-medium text-gray-800">3D Web Development</p>
                                <p class="text-md font-light text-gray-400">"Creating immersive, interactive 3D websites for engaging user experiences."</p>
                                <div class="justify-starts mt-4 flex flex-wrap items-center">
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">Kotlin</div>
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">React Native</div>
                                </div>
                            </div>
                        </a>
                    </article>
                    <article class="h-90 col-span-1 m-auto min-h-full cursor-pointer overflow-hidden rounded-lg pb-2 shadow-sm hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5">
                        <a href="#" class="block h-full w-full">
                            <img class="max-h-40 w-full object-cover" alt="featured image" src={Img2} />
                            <div class="w-full bg-white p-4">
                                <p class="text-md font-medium text-cyan-500">4.8 Rating</p>
                                <p class="mb-2 text-xl font-medium text-gray-800">Digital Marketing</p>
                                <p class="text-md font-light text-gray-400">"Boosting brand visibility with data-driven digital marketing strategies."</p>
                                <div class="justify-starts mt-4 flex flex-wrap items-center">
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">Kotlin</div>
                                    <div class="mr-2 mt-1 rounded-2xl bg-blue-100 py-1.5 px-4 text-xs text-gray-600">React Native</div>
                                </div>
                            </div>
                        </a>
                    </article>

                </div>
            </section>


            <section>
                <h1 class="mb-12 ps-10 text-left font-sans text-4xl mt-5 text-cyan-500 font-semibold">UI-UX Portfolio Design</h1>
                <div className="grid  grid-cols-1 sm:grid-cols-2 gap-4">
                    <div className="w-full h-80  overflow-hidden relative  ">
                        <img src={a3} className="p-2 sm:p-5     w-full  h-auto  bg-cover bg-center bg-scroll absolute top-0  hover:-top-[270%]" style={{ transition: "top 5s ease" }} alt="" />
                    </div>
                    <hr className="block sm:hidden" />
                    <div className="w-full h-80  overflow-hidden relative  ">
                        <img src={a3} className="p-2 sm:p-5     w-full  h-auto  bg-cover bg-center bg-scroll absolute top-0  hover:-top-[270%]" style={{ transition: "top 5s ease" }} alt="" />
                    </div>
                    <hr className="block sm:hidden" />

                    <div className="w-full h-80  overflow-hidden relative  ">
                        <img src={a3} className="p-2 sm:p-5     w-full  h-auto  bg-cover bg-center bg-scroll absolute top-0  hover:-top-[270%]" style={{ transition: "top 5s ease" }} alt="" />
                    </div>
                    <hr className="block sm:hidden" />

                    <div className="w-full h-80  overflow-hidden relative  ">
                        <img src={a3} className="p-2 sm:p-5     w-full  h-auto  bg-cover bg-center bg-scroll absolute top-0  hover:-top-[270%]" style={{ transition: "top 5s ease" }} alt="" />
                    </div>
                    <hr className="block sm:hidden" />


                </div>

            </section>



            {/* <Slider /> */}

            {/* Section 1 Start */}
            {/* <div className="grid grid-cols-2">

                <div className="relative z-10 flex flex-col items-center">
                    <span className="mb-1.5 inline-block rounded-full    px-3 py-1.5 text-sm">
                        Tech Saksham
                    </span>
                    <h1 className="max-w-3xl bg-gradient-to-br from-white to-gray-400 bg-clip-text text-center text-3xl font-medium leading-tight text-transparent sm:text-4xl sm:leading-tight md:text-6xl md:leading-tight">
                        "Your Vision, Our Code: Building the Future Together"
                    </h1>
                    <p className="my-6 max-w-xl text-center text-base leading-relaxed md:text-lg md:leading-relaxed">
                        "Tech Saksham builds custom software solutions that help businesses grow and operate more efficiently. Our expert team turns your ideas into powerful digital tools tailored to your needs."
                    </p>
                    <motion.button
                        style={{
                            border,
                            boxShadow,
                        }}
                        whileHover={{
                            scale: 1.015,
                        }}
                        whileTap={{
                            scale: 0.985,
                        }}
                        className="group relative flex w-fit items-center  rounded-full bg-gray-950/10 px-4 py-2 text-gray-50 transition-colors hover:bg-gray-950/50"
                    >
                        Contact Us
                        <FiArrowRight className="transition-transform group-hover:-rotate-45 group-active:-rotate-12" />
                    </motion.button>
                </div>
                <div>

                </div>
            </div> */}
            {/* Section 1 End */}

            {/* Section 2 Start */}
            {/* <div className="block overflow-hidden"> */}

            {/* <div className="relative z-10 flex flex-col items-center">
                        <span className="mb-1.5 inline-block rounded-full    px-3 py-1.5 text-sm">
                            Tech Saksham
                        </span>
                        <h1 className="max-w-3xl bg-gradient-to-br from-white to-gray-400 bg-clip-text text-center text-3xl font-medium leading-tight text-transparent sm:text-4xl sm:leading-tight md:text-6xl md:leading-tight">
                            "Your Vision, Our Code: Building the Future Together"
                        </h1>
                        <p className="my-6 max-w-xl text-center text-base leading-relaxed md:text-lg md:leading-relaxed">
                            "Tech Saksham builds custom software solutions that help businesses grow and operate more efficiently. Our expert team turns your ideas into powerful digital tools tailored to your needs."
                        </p>
                        <motion.button
                            style={{
                                border,
                                boxShadow,
                            }}
                            whileHover={{
                                scale: 1.015,
                            }}
                            whileTap={{
                                scale: 0.985,
                            }}
                            className="group relative flex w-fit items-center  rounded-full bg-gray-950/10 px-4 py-2 text-gray-50 transition-colors hover:bg-gray-950/50"
                        >
                            Contact Us
                            <FiArrowRight className="transition-transform group-hover:-rotate-45 group-active:-rotate-12" />
                        </motion.button>
                    </div> */}
            {/* <div>
                    <div>
                        <h1 className="sm:text-3xl ms-5 text-2xl  font-serif">Empowering Your Business with Our
                            Cutting-Edge <br /> Software Solutions</h1>
                    </div>
                    <div className="grid sm:grid-cols-2">
                        <div className=" hidden sm:flex">
                            <img src="https://images.pexels.com/photos/270637/pexels-photo-270637.jpeg?auto=compress&cs=tinysrgb&w=600" className="h-52 z-10 absolute sm:ms-32 mt-32 shadow-2xl" alt="" />
                            <img src="https://images.pexels.com/photos/7213438/pexels-photo-7213438.jpeg?auto=compress&cs=tinysrgb&w=600" className="h-96 z-20 absolute sm:ms-56 mt-10 shadow-2xl" alt="" />
                        </div>
                        <div>
                            <div className="p-4 sm:p-10">
                                <h1 className="m-2 font-bold">App Development :</h1>
                                <h1 className="ms-8 text-justify" >Web development involves creating and maintaining websites. It includes front-end design, back-end programming, and user experience optimization.</h1>
                                <h1 className="font-semibold m-8">Total Web Project : {'10'}</h1>
                                <h1 className="font-semibold m-8">Client Rating: {'4.7'}</h1>
                            </div>
                            <div className="flex justify-center">
                                <div className="flex">
                                    <button className="border ps-5 pe-5 hover:bg-gray-600"><i class="fa-solid fa-caret-left"></i></button>
                                    <div className="flex justify-center items-center">
                                        <div className="border-2 w-28 border-violet-800"></div>
                                    </div>
                                    <button className="border ps-5 pe-5 hover:bg-gray-600"><i class="fa-solid fa-caret-right"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div> */}
            {/* Section 2 End */}
            {/* <div className="m-10 sm:block sm:m-32">

            </div> */}
            {/* Section 3 Start */}
            {/* <div className="block overflow-hidden">

                <div className="block overflow-hidden">
                    <div>
                        <h1 className="text-xl sm:text-3xl ms-5 font-semibold mb-4">UI-UX Design Portfolio :</h1>
                    </div>
                    <div className="grid  grid-cols-1 sm:grid-cols-2 gap-4">
                        <div className="w-full h-80  overflow-hidden relative  ">
                            <img src={a3} className="p-2 sm:p-5     w-full  h-auto  bg-cover bg-center bg-scroll absolute top-0  hover:-top-[270%]" style={{ transition: "top 5s ease" }} alt="" />
                        </div>
                        <hr className="block sm:hidden" />
                        <div className="w-full h-80  overflow-hidden relative  ">
                            <img src={a3} className="p-2 sm:p-5     w-full  h-auto  bg-cover bg-center bg-scroll absolute top-0  hover:-top-[270%]" style={{ transition: "top 5s ease" }} alt="" />
                        </div>
                        <hr className="block sm:hidden" />

                        <div className="w-full h-80  overflow-hidden relative  ">
                            <img src={a3} className="p-2 sm:p-5     w-full  h-auto  bg-cover bg-center bg-scroll absolute top-0  hover:-top-[270%]" style={{ transition: "top 5s ease" }} alt="" />
                        </div>
                        <hr className="block sm:hidden" />

                        <div className="w-full h-80  overflow-hidden relative  ">
                            <img src={a3} className="p-2 sm:p-5     w-full  h-auto  bg-cover bg-center bg-scroll absolute top-0  hover:-top-[270%]" style={{ transition: "top 5s ease" }} alt="" />
                        </div>
                        <hr className="block sm:hidden" />


                    </div>
                </div>


            </div> */}
            {/* Section 3 End */}

            {/* Section 4 Start computer */}
            <div className="hidden sm:block">
                <div className="m-5 ">
                    <h1 class="mb-12 ps-10 text-left font-sans text-4xl mt-5 text-cyan-500 font-semibold">The 5-Step Process of Software Development <br /> for Clients :</h1>
                </div>
                <div className="grid grid-cols-12">
                    <div className="col-start-1 col-end-6  "></div>
                    <div className="flex items-center justify-center col-start-6 col-end-8  ">
                        <div className=" grid grid-cols-1 " onClick={openModal2}>
                            <div className='  text-center'>
                                <img src={step2} className=" cursor-pointer bg-cover  m-auto rounded-full h-40 w-40 p-2 hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className=' text-center   '>
                                <h1 className="text-cyan-600">Design & Prototyping</h1>
                            </div>
                        </div>
                        {isOpen2 && (
                            <div className="fixed inset-0 flex items-center justify-center z-50 shadow-2xl" >

                                <div className="fixed inset-0 bg-black bg-opacity-50" onClick={closeModal2}></div>


                                <div className="bg-white p-2 rounded-lg shadow-sm hover:shadow-2xl z-10">
                                    <div>
                                        <img src={step2} className=' w-96' alt="" />
                                    </div>
                                    <br />
                                    <div className='text-center p-5'>
                                        <h1 className='text-justify'><span className='font-bold'>Goal:</span>  Understand the client’s needs, <br />  objectives, and constraints.</h1>
                                        <h1 className='text-justify'><span className='font-bold'>Actions:</span> Meetings with stakeholders,<br />  requirement workshops, questionnaires, <br />  and use case identification.</h1>
                                        <h1 className='text-justify'><span className='font-bold'>Output:</span> A clear and detailed requirements <br /> document or a product backlog <br /> in agile environments.</h1>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-start-8 col-end-13  "></div>



                    <div className="col-start-1 col-end-3  "></div>
                    <div className="flex items-center justify-center col-start-3 col-end-5  ">
                        <div className=" grid grid-cols-1 " onClick={openModal}>
                            <div className='  text-center'>
                                <img src={step1} className="cursor-pointer  bg-cover  m-auto rounded-full h-40 w-40 p-2 hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className=' text-center   '>
                                <h1 className="text-cyan-600">Requirement Gathering & Analysis</h1>
                            </div>
                        </div>


                        {isOpen && (
                            <div className="fixed inset-0 flex items-center justify-center z-50 shadow-2xl" >

                                <div className="fixed inset-0 bg-black bg-opacity-50" onClick={closeModal}></div>


                                <div className="bg-white p-2 rounded-lg shadow-sm hover:shadow-2xl z-10">
                                    <div>
                                        <img src={step1} className='w-96 hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5' alt="" />
                                    </div>
                                    <br />
                                    <div className='text-center p-5'>
                                        <h1 className='text-justify'><span className='font-bold'>Goal:</span>  Understand the client’s needs, <br />  objectives, and constraints.</h1>
                                        <h1 className='text-justify'><span className='font-bold'>Actions:</span> Meetings with stakeholders,<br />  requirement workshops, questionnaires, <br />  and use case identification.</h1>
                                        <h1 className='text-justify'><span className='font-bold'>Output:</span> A clear and detailed requirements <br /> document or a product backlog <br /> in agile environments.</h1>
                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                    <div className="flex items-center justify-center col-start-5 col-end-9  ">
                        <div className=" text-center justify-center">
                            <img src={step1} className="rounded-full h-60 w-60 p-2 hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                        </div>
                    </div>
                    <div className="flex items-center justify-center col-start-9 col-end-11  ">
                        <div className=" grid grid-cols-1 ">
                            <div className='  text-center'>
                                <img src={step3} className=" bg-cover  m-auto rounded-full h-40 w-40 p-2 hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className=' text-center   '>
                                <h1 className="text-cyan-600">Development (Coding)</h1>
                            </div>
                        </div>

                    </div>
                    <div className="col-start-11 col-end-13  "></div>



                    <div className="col-start-1 col-end-5  "></div>
                    <div className=" flex items-center justify-center col-start-5 col-end-7  ">

                        <div className=" grid grid-cols-1 ">
                            <div className='  text-center'>
                                <img src={step4} className=" bg-cover  m-auto rounded-full h-40 w-40 p-2 hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className=' text-center   '>
                                <h1 className="text-cyan-600">Deployment & Maintenance</h1>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-center col-start-7 col-end-9  ">
                        <div className=" grid grid-cols-1 ">
                            <div className='  text-center'>
                                <img src={step5} className=" bg-cover  m-auto rounded-full h-40 w-40 p-2 hover:shadow-2xl transition-transform duration-200 hover:-translate-y-5" alt="" />
                            </div>
                            <div className=' text-center   '>
                                <h1 className="text-cyan-600">Testing & Quality Assurance</h1>
                            </div>
                        </div>

                    </div>
                    <div className="col-start-9 col-end-13  "></div>

                </div>
            </div>

            {/* Section 4 End Computer */}
            {/* Section 4 Start Mobile */}
            <div className="block sm:hidden">

                <div className="m-2 mb-5 mt-4">
                    <h1 className="text-xl">The 5-Step Process of Software Development for Clients :</h1>
                </div>
                <ol class=" overflow-hidden space-y-8">
                    <li onClick={openModal} class="relative flex-1 after:content-['']  after:w-0.5 after:h-full  after:bg-indigo-600 after:inline-block after:absolute after:-bottom-11 after:left-1/2">
                        <div class="flex items-center justify-center gap-8 w-full max-w-sm">
                            <div class="flex items-center gap-3.5 bg-indigo-50 p-3.5 rounded-xl relative z-10 border border-indigo-600 w-full">
                                <div class="rounded-lg bg-indigo-600 flex items-center justify-center">
                                    <span class="text-white ">
                                        <img src={step1} className="w-32" alt="" />

                                    </span>
                                </div>
                                <div
                                    class=" flex items-start rounded-md justify-center flex-col ">
                                    <h6 class="text-base font-semibold text-black mb-0.5">
                                        Personal
                                        Info</h6>
                                    <p class="text-xs font-normal text-gray-500">Just your
                                        personal
                                        information</p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="relative flex-1 after:content-['']  after:w-0.5 after:h-full  after:bg-gray-200 after:inline-block after:absolute after:-bottom-12 after:left-1/2">
                        <div class="flex items-center justify-center gap-8 w-full max-w-sm">
                            <div class="flex items-center gap-3.5 bg-gray-50 p-3.5 rounded-xl relative z-10 border border-gray-50 w-full">
                                <div class=" flex items-start rounded-md justify-center flex-col">
                                    <h6 class="text-base font-semibold text-black mb-0.5">
                                        Account
                                        Info</h6>
                                    <p class="text-xs font-normal text-gray-500">Anything
                                        you want for your credentials
                                    </p>
                                </div>
                                <div class="rounded-lg bg-gray-200 flex items-center justify-center">
                                    <img src={step2} className="w-32" alt="" />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="relative flex-1 after:content-['']  after:w-0.5 after:h-full  after:bg-gray-200 after:inline-block after:absolute after:-bottom-12 after:left-1/2">
                        <div class="flex items-center justify-center gap-8 w-full max-w-sm">
                            <div class="flex items-center gap-3.5 bg-gray-50 p-3.5 rounded-xl relative z-10 border border-gray-50 w-full">
                                <div class="rounded-lg bg-gray-200 flex items-center justify-center">
                                    <img src={step3} className="w-32" alt="" />
                                </div>
                                <div class=" flex items-start rounded-md justify-center flex-col">
                                    <h6 class="text-base font-semibold text-black mb-0.5">
                                        Account
                                        Info</h6>
                                    <p class="text-xs font-normal text-gray-500">Anything
                                        you want for your credentials
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="relative flex-1 after:content-['']  after:w-0.5 after:h-full  after:bg-gray-200 after:inline-block after:absolute after:-bottom-12 after:left-1/2">
                        <div class="flex items-center justify-center gap-8 w-full max-w-sm">
                            <div class="flex items-center gap-3.5 bg-gray-50 p-3.5 rounded-xl relative z-10 border border-gray-50 w-full">
                                <div class=" flex items-start rounded-md justify-center flex-col">
                                    <h6 class="text-base font-semibold text-black mb-0.5">
                                        Account
                                        Info</h6>
                                    <p class="text-xs font-normal text-gray-500">Anything
                                        you want for your credentials
                                    </p>
                                </div>
                                <div class="rounded-lg bg-gray-200 flex items-center justify-center">
                                    <img src={step4} className="w-32" alt="" />
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="relative flex-1 after:content-['']  after:w-0.5 after:h-full  after:bg-gray-200 after:inline-block after:absolute after:-bottom-12 after:left-1/2">
                        <div class="flex items-center justify-center gap-8 w-full max-w-sm">
                            <div class="flex items-center gap-3.5 bg-gray-50 p-3.5 rounded-xl relative z-10 border border-gray-50 w-full">
                                <div class="rounded-lg bg-gray-200 flex items-center justify-center">
                                    <img src={step5} className="w-32" alt="" />
                                </div>
                                <div class=" flex items-start rounded-md justify-center flex-col">
                                    <h6 class="text-base font-semibold text-black mb-0.5">
                                        Account
                                        Info</h6>
                                    <p class="text-xs font-normal text-gray-500">Anything
                                        you want for your credentials
                                    </p>
                                </div>
                            </div>
                        </div>
                    </li>
                </ol>
            </div>

            {isOpen && (
                <div className="fixed inset-0 flex items-center justify-center z-50 shadow-2xl" >

                    <div className="fixed inset-0 bg-black bg-opacity-50" onClick={closeModal}></div>


                    <div className="bg-white p-2 rounded-lg shadow-sm hover:shadow-2xl z-10">
                        <div>
                            <img src={step1} className='w-96' alt="" />
                        </div>
                        <br />
                        <div className='text-center p-5'>
                            <h1 className='text-justify'><span className='font-bold'>Goal:</span>  Understand the client’s needs, <br />  objectives, and constraints.</h1>
                            <h1 className='text-justify'><span className='font-bold'>Actions:</span> Meetings with stakeholders,<br />  requirement workshops, questionnaires, <br />  and use case identification.</h1>
                            <h1 className='text-justify'><span className='font-bold'>Output:</span> A clear and detailed requirements <br /> document or a product backlog <br /> in agile environments.</h1>
                        </div>
                    </div>
                </div>
            )}
            {/* Section 4 End Mobile */}

            {/* Section 5 Start */}
            {/* Section 5 End */}
            {/* Section 6 Start */}
            <div className="block sm:hidden">
                <Tabs />
            </div>
            {/* Section 6 End */}
            {/* Section 7 Start */}
            <div className="hidden sm:block">
                <Tabscom />
            </div>
            {/* Section 7 End */}

            {/* 
<Testimonials/> */}
            <section class="py-6 text-blue-900 sm:py-16 lg:py-20">
                <div class="mx-auto max-w-2xl px-4 sm:px-6 lg:max-w-screen-lg lg:px-8">
                    <div class="flex flex-col lg:flex-row">
                        <div class="relative mx-auto mb-10 flex h-96 overflow-hidden rounded-xl bg-cyan-400   shadow sm:mt-20 lg:h-auto lg:max-w-md lg:pt-20">
                            <img class="absolute top-0 h-full w-full object-cover opacity-10" src={a1} alt="" />
                            <div class="relative mt-auto w-full">
                                <div class="flex flex-col p-6 lg:px-7 lg:py-8">
                                    <div class="">
                                        <blockquote class="">
                                            <p class="text-3xl font-bold text-white sm:text-3xl">"Our mission at Tech Saksham is to deliver innovative software solutions that drive efficiency and growth for our clients. I'm proud of the impact we've made and the talented team behind it."</p>
                                        </blockquote>
                                    </div>

                                    <div class="mt-10 flex items-center">
                                        <img class="h-11 w-11 flex-shrink-0 rounded-full object-cover"  src="https://scontent.fnag11-1.fna.fbcdn.net/v/t1.6435-1/78246786_161352165264047_7794142676072267776_n.jpg?stp=dst-jpg_s200x200&_nc_cat=103&ccb=1-7&_nc_sid=0ecb9b&_nc_ohc=QAEb6BY9y_sQ7kNvgFR3e4n&_nc_ht=scontent.fnag11-1.fna&oh=00_AYC77IxGv2a5NJZQBaCRCh97A6Z6ofRg_LmefvudI0iFww&oe=670E4762"  alt="" />
                                        <div class="ml-4 text-white">
                                            <p class="text-base font-bold">Saksham Bangale</p>
                                            <p class="text-blue-90 mt-0.5 text-sm">CEO OF Tech Saksham</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="relative mx-auto grid max-w-lg grid-cols-1 gap-y-14 lg:pl-20">
                            <div class="flex flex-col bg-white">
                                <div class="">
                                    <blockquote class="">
                                        <p class="text-lg leading-relaxed">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Praesentium dolores facere repellendus, velit quis fugiat."</p>
                                    </blockquote>
                                </div>

                                <div class="mt-4 flex items-center">
                                    <img class="h-11 w-11 flex-shrink-0 rounded-full object-cover" src="https://scontent.fnag11-1.fna.fbcdn.net/v/t1.6435-1/78246786_161352165264047_7794142676072267776_n.jpg?stp=dst-jpg_s200x200&_nc_cat=103&ccb=1-7&_nc_sid=0ecb9b&_nc_ohc=QAEb6BY9y_sQ7kNvgFR3e4n&_nc_ht=scontent.fnag11-1.fna&oh=00_AYC77IxGv2a5NJZQBaCRCh97A6Z6ofRg_LmefvudI0iFww&oe=670E4762" alt="" />
                                    <div class="ml-4">
                                        <p class="text-base font-bold">Saksham Bangale</p>

                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col bg-white">
                                <div class="">
                                    <blockquote class="">
                                        <p class="text-lg leading-relaxed">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Praesentium dolores facere repellendus, velit quis fugiat."</p>
                                    </blockquote>
                                </div>

                                <div class="mt-4 flex items-center">
                                    <img class="h-11 w-11 flex-shrink-0 rounded-full object-cover" src="https://scontent.fnag11-1.fna.fbcdn.net/v/t1.6435-1/78246786_161352165264047_7794142676072267776_n.jpg?stp=dst-jpg_s200x200&_nc_cat=103&ccb=1-7&_nc_sid=0ecb9b&_nc_ohc=QAEb6BY9y_sQ7kNvgFR3e4n&_nc_ht=scontent.fnag11-1.fna&oh=00_AYC77IxGv2a5NJZQBaCRCh97A6Z6ofRg_LmefvudI0iFww&oe=670E4762" alt="" />
                                    <div class="ml-4">
                                        <p class="text-base font-bold">Saksham Bangale</p>
                                       
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col bg-white">
                                <div class="">
                                    <blockquote class="">
                                        <p class="text-lg leading-relaxed">"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Praesentium dolores facere repellendus, velit quis fugiat."</p>
                                    </blockquote>
                                </div>

                                <div class="mt-4 flex items-center">
                                    <img class="h-11 w-11 flex-shrink-0 rounded-full object-cover" src="https://scontent.fnag11-1.fna.fbcdn.net/v/t1.6435-1/78246786_161352165264047_7794142676072267776_n.jpg?stp=dst-jpg_s200x200&_nc_cat=103&ccb=1-7&_nc_sid=0ecb9b&_nc_ohc=QAEb6BY9y_sQ7kNvgFR3e4n&_nc_ht=scontent.fnag11-1.fna&oh=00_AYC77IxGv2a5NJZQBaCRCh97A6Z6ofRg_LmefvudI0iFww&oe=670E4762" alt="" />
                                    <div class="ml-4">
                                        <p class="text-base font-bold">Saksham Bangale</p>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/*  */}
          <div class="sm:p-10 my-auto">
                <section class="mx-auto max-w-screen-xl md:rounded-md md:border md:shadow-sm hover:shadow-2xl">
                    <div class="grid grid-cols-4 text-gray-800 lg:grid-cols-3">
                        <div class="col-span-4 bg-gray-50 px-8 py-10 text-gray-800 md:col-span-2 md:border-r md:px-10 md:py-12 lg:col-span-1">
                            <h2 class="mb-8 text-2xl font-black">Contact me</h2>
                            <ul>
                                <li class="mb-6 flex items-start text-left">
                                    <svg class="shrink-0 mr-6 text-2xl text-gray-500" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5l-8-5V6l8 5l8-5v2z" /></svg>
                                    <div>
                                        <a class="cursor-pointer font-serif text-base md:text-lg" href="#">sakshambangale779@gmail.com</a>
                                        <span class="block text-xs uppercase">email</span>
                                    </div>
                                </li>
                                <li class="my-6 flex items-center text-left">
                                    <svg class="shrink-0 mr-6 text-2xl text-gray-500" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 1024 1024"><path fill="currentColor" d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448s448-200.6 448-448S759.4 64 512 64zm215.3 337.7c.3 4.7.3 9.6.3 14.4c0 146.8-111.8 315.9-316.1 315.9c-63 0-121.4-18.3-170.6-49.8c9 1 17.6 1.4 26.8 1.4c52 0 99.8-17.6 137.9-47.4c-48.8-1-89.8-33-103.8-77c17.1 2.5 32.5 2.5 50.1-2a111 111 0 0 1-88.9-109v-1.4c14.7 8.3 32 13.4 50.1 14.1a111.13 111.13 0 0 1-49.5-92.4c0-20.7 5.4-39.6 15.1-56a315.28 315.28 0 0 0 229 116.1C492 353.1 548.4 292 616.2 292c32 0 60.8 13.4 81.1 35c25.1-4.7 49.1-14.1 70.5-26.7c-8.3 25.7-25.7 47.4-48.8 61.1c22.4-2.4 44-8.6 64-17.3c-15.1 22.2-34 41.9-55.7 57.6z" /></svg>
                                    <div>
                                        <a class="cursor-pointer font-serif text-base md:text-lg" href="#">Wardha</a>
                                        <span class="block text-xs uppercase">Address</span>
                                    </div>
                                </li>
                                <li class="my-6 flex items-center text-left">
                                    <svg class="shrink-0 mr-6 text-2xl text-gray-500" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" fill-rule="evenodd" d="M1 2.838A1.838 1.838 0 0 1 2.838 1H21.16A1.837 1.837 0 0 1 23 2.838V21.16A1.838 1.838 0 0 1 21.161 23H2.838A1.838 1.838 0 0 1 1 21.161V2.838Zm8.708 6.55h2.979v1.496c.43-.86 1.53-1.634 3.183-1.634c3.169 0 3.92 1.713 3.92 4.856v5.822h-3.207v-5.106c0-1.79-.43-2.8-1.522-2.8c-1.515 0-2.145 1.089-2.145 2.8v5.106H9.708V9.388Zm-5.5 10.403h3.208V9.25H4.208v10.54ZM7.875 5.812a2.063 2.063 0 1 1-4.125 0a2.063 2.063 0 0 1 4.125 0Z" clip-rule="evenodd" /></svg>
                                    <div>
                                        <p class="font-serif text-base md:text-lg">Saksham Bangale</p>
                                        <span class="block text-xs uppercase">LinkedIn</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="order-first col-span-4 max-w-screen-md px-8 py-10 md:order-last md:col-span-2 md:px-10 md:py-12">
                            <h2 class="mb-8 text-2xl font-black">Get in touch</h2>
                            <p class="mt-2 mb-4 font-sans text-sm tracking-normal">Don't be shy to ask me a question.</p>
                            <form action="">
                                <div class="md:col-gap-4 mb-5 grid md:grid-cols-2">
                                    <input class="col-span-1 w-full border-b py-3 text-sm outline-none focus:border-b-2 focus:border-black" type="text" placeholder="Name" name="name" />
                                    <input class="col-span-1 w-full border-b py-3 text-sm outline-none focus:border-b-2 focus:border-black" type="email" placeholder="Email" name="email" />
                                </div>
                                <textarea class="mb-10 w-full resize-y whitespace-pre-wrap border-b py-3 text-sm outline-none focus:border-b-2 focus:border-black" id="" rows="6" placeholder="Question" name="question"></textarea>
                                <button type="submit" class="group flex cursor-pointer items-center rounded-xl bg-cyan-600 bg-none px-8 py-4 text-center font-semibold leading-tight text-white">
                                    Send
                                    <svg class="group-hover:ml-8 ml-4 transition-all" aria-hidden="true" role="img" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.912 12H4L2.023 4.135A.662.662 0 0 1 2 3.995c-.022-.721.772-1.221 1.46-.891L22 12L3.46 20.896c-.68.327-1.464-.159-1.46-.867a.66.66 0 0 1 .033-.186L3.5 15" /></svg>
                                </button>
                            </form>
                        </div>
                    </div>
                </section>
            </div> 

            {/*  */}


            {/* <section class="bg-white dark:bg-gray-900">
                <div class="gap-1 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
                    <div class="grid grid-cols-2 gap-4 mt-8">
                        <img class="w-full rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png" alt="office content 1" />
                        <img class="mt-4 w-full lg:mt-10 rounded-lg" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png" alt="office content 2" />
                    </div>
                    <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">We didn't reinvent the wheel</h2>
                        <p class="mb-4">We are strategists, designers and developers. Innovators and problem solvers. Small enough to be simple and quick, but big enough to deliver the scope you want at the pace you need. Small enough to be simple and quick, but big enough to deliver the scope you want at the pace you need.</p>
                        <p>We are strategists, designers and developers. Innovators and problem solvers. Small enough to be simple and quick.</p>
                    </div>
                </div>
            </section> */}
        </>
    )
}


const Tabs = () => {
    // State to track the active tab
    const [activeTab, setActiveTab] = useState('tab1');

    // Function to switch tabs
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="tabs-container">
            {/* Tab headers */}
            <div className="border-b border-gray-200">
                <nav className="grid grid-cols-2 space-x-4">
                    <button
                        className={`py-2 px-4 font-medium ${activeTab === 'tab1'
                            ? 'border-b-2 border-indigo-600 text-indigo-600'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                        onClick={() => handleTabClick('tab1')}
                    >
                        App Development
                    </button>
                    <button
                        className={`py-2 px-4 font-medium ${activeTab === 'tab2'
                            ? 'border-b-2 border-indigo-600 text-indigo-600'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                        onClick={() => handleTabClick('tab2')}
                    >
                        Web Development
                    </button>
                    <button
                        className={`py-2 px-4 font-medium ${activeTab === 'tab3'
                            ? 'border-b-2 border-indigo-600 text-indigo-600'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                        onClick={() => handleTabClick('tab3')}
                    >
                        Frontend
                    </button>
                    <button
                        className={`py-2 px-4 font-medium ${activeTab === 'tab4'
                            ? 'border-b-2 border-indigo-600 text-indigo-600'
                            : 'text-gray-500 hover:text-gray-700'
                            }`}
                        onClick={() => handleTabClick('tab4')}
                    >
                        Backend
                    </button>
                </nav>
            </div>

            {/* Tab content */}
            <div className="p-4">
                {activeTab === 'tab1' && (
                    <div>
                        <div>
                            <h1 className="text-xl font-semibold">App Development :</h1>
                            <h1 className="text-xs mt-4">"From concept to launch, we deliver top-notch app development services,
                                combining innovation and reliability to bring your vision to life."</h1>
                        </div>
                        <div className="grid grid-cols-2 gap-5 pt-5">
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'tab2' && (
                    <div>
                        <div>
                            <h1 className="text-xl font-semibold">App Development :</h1>
                            <h1 className="text-xs mt-4">"From concept to launch, we deliver top-notch app development services,
                                combining innovation and reliability to bring your vision to life."</h1>
                        </div>
                        <div className="grid grid-cols-2 gap-5 pt-5">
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'tab3' && (
                    <div>
                        <div>
                            <h1 className="text-xl font-semibold">App Development :</h1>
                            <h1 className="text-xs mt-4">"From concept to launch, we deliver top-notch app development services,
                                combining innovation and reliability to bring your vision to life."</h1>
                        </div>
                        <div className="grid grid-cols-2 gap-5 pt-5">
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                        </div>
                    </div>
                )}
                {activeTab === 'tab4' && (
                    <div>
                        <div>
                            <h1 className="text-xl font-semibold">App Development :</h1>
                            <h1 className="text-xs mt-4">"From concept to launch, we deliver top-notch app development services,
                                combining innovation and reliability to bring your vision to life."</h1>
                        </div>
                        <div className="grid grid-cols-2 gap-5 pt-5">
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                            <div className="flex justify-center items-center">
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

const Tabscom = () => {
    // State to track the active tab
    const [activeTabcom, setActiveTabcom] = useState('tab1');

    // Function to switch tabs
    const handleTabClickcom = (tab) => {
        setActiveTabcom(tab);
    };

    return (
        <>
            <hr className="mt-5" />
            <div className="grid grid-cols-8">
                <div className="col-start-1 col-end-3 flex justify-center items-center">
                    <img src={Logo} className="w-32 h-32" alt="" />
                </div>
                <div className="col-start-3 col-end-9 p-4 flex justify-center items-center">
                    <div className="">
                        <h1 className="text-2xl font-semibold">"Building Better with a Tech Stack That Stands Out"</h1>
                        <h1 className="ms-16 mt-5">         "We utilize the most effective technologies for your product, whether they are the latest trends
                            or proven classics."</h1>
                    </div>
                </div>
            </div>
            <div className="tabs-container grid grid-cols-8">
                {/* Tab headers */}
                <div className="flex items-center justify-center col-start-1 col-end-3 border-b border-gray-200">
                    <div>
                        <div className="text-center">
                            <h1 className="text-xl font-semibold">Technology We Use</h1>
                        </div>
                        <nav className="grid grid-cols-1 space-x-4">
                            <button
                                className={`py-2 px-4 font-medium ${activeTabcom === 'tab1'
                                    ? 'border-b-2 border-indigo-600 text-indigo-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabClickcom('tab1')}
                            >
                                App Development
                            </button>
                            <button
                                className={`py-2 px-4 font-medium ${activeTabcom === 'tab2'
                                    ? 'border-b-2 border-indigo-600 text-indigo-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabClickcom('tab2')}
                            >
                                Web Development
                            </button>
                            <button
                                className={`py-2 px-4 font-medium ${activeTabcom === 'tab3'
                                    ? 'border-b-2 border-indigo-600 text-indigo-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabClickcom('tab3')}
                            >
                                Frontend
                            </button>
                            <button
                                className={`py-2 px-4 font-medium ${activeTabcom === 'tab4'
                                    ? 'border-b-2 border-indigo-600 text-indigo-600'
                                    : 'text-gray-500 hover:text-gray-700'
                                    }`}
                                onClick={() => handleTabClickcom('tab4')}
                            >
                                Backend
                            </button>
                        </nav>
                    </div>
                </div>

                {/* Tab content */}
                <div className="col-start-3 col-end-9 p-4">
                    {activeTabcom === 'tab1' && (
                        <div>
                            <div>
                                <h1 className="text-xl font-semibold">App Development :</h1>
                                <h1 className="text-xs mt-4">"From concept to launch, we deliver top-notch app development services,
                                    combining innovation and reliability to bring your vision to life."</h1>
                            </div>
                            <div className="grid grid-cols-4 gap-5 pt-5">
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                            </div>
                        </div>
                    )}
                    {activeTabcom === 'tab2' && (
                        <div>
                            <div>
                                <h1 className="text-xl font-semibold">App Development :</h1>
                                <h1 className="text-xs mt-4">"From concept to launch, we deliver top-notch app development services,
                                    combining innovation and reliability to bring your vision to life."</h1>
                            </div>
                            <div className="grid grid-cols-2 gap-5 pt-5">
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                            </div>
                        </div>
                    )}
                    {activeTabcom === 'tab3' && (
                        <div>
                            <div>
                                <h1 className="text-xl font-semibold">App Development :</h1>
                                <h1 className="text-xs mt-4">"From concept to launch, we deliver top-notch app development services,
                                    combining innovation and reliability to bring your vision to life."</h1>
                            </div>
                            <div className="grid grid-cols-2 gap-5 pt-5">
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                            </div>
                        </div>
                    )}
                    {activeTabcom === 'tab4' && (
                        <div>
                            <div>
                                <h1 className="text-xl font-semibold">App Development :</h1>
                                <h1 className="text-xs mt-4">"From concept to launch, we deliver top-notch app development services,
                                    combining innovation and reliability to bring your vision to life."</h1>
                            </div>
                            <div className="grid grid-cols-2 gap-5 pt-5">
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                                <div className="flex justify-center items-center">
                                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png" className="w-20 h-20 rounded-md border border-gray-600 p-1" alt="" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};







const Testimonials = () => {
    const testimonials = [
        {
            id: 1,
            rating: 4.9,
            text: "Pagedone has made it possible for me to stay on top of my portfolio and make informed decisions quickly and easily.",
            author: "Jane D.",
            role: "CEO",
            img: "https://pagedone.io/asset/uploads/1696229969.png",
        },
        // {
        //     id: 2,
        //     rating: 4.9,
        //     text: "Thanks to pagedone, I feel more informed and confident about my investment decisions than ever before.",
        //     author: "Harsh P.",
        //     role: "Product Designer",
        //     img: "https://pagedone.io/asset/uploads/1696229994.png",
        // },
        // {
        //     id: 2,
        //     rating: 4.9,
        //     text: "Thanks to pagedone, I feel more informed and confident about my investment decisions than ever before.",
        //     author: "Harsh P.",
        //     role: "Product Designer",
        //     img: "https://pagedone.io/asset/uploads/1696229994.png",
        // },
        // {
        //     id: 2,
        //     rating: 4.9,
        //     text: "Thanks to pagedone, I feel more informed and confident about my investment decisions than ever before.",
        //     author: "Harsh P.",
        //     role: "Product Designer",
        //     img: "https://pagedone.io/asset/uploads/1696229994.png",
        // },
        // {
        //     id: 2,
        //     rating: 4.9,
        //     text: "Thanks to pagedone, I feel more informed and confident about my investment decisions than ever before.",
        //     author: "Harsh P.",
        //     role: "Product Designer",
        //     img: "https://pagedone.io/asset/uploads/1696229994.png",
        // },
        // {
        //     id: 2,
        //     rating: 4.9,
        //     text: "Thanks to pagedone, I feel more informed and confident about my investment decisions than ever before.",
        //     author: "Harsh P.",
        //     role: "Product Designer",
        //     img: "https://pagedone.io/asset/uploads/1696229994.png",
        // }, {
        //     id: 2,
        //     rating: 4.9,
        //     text: "Thanks to pagedone, I feel more informed and confident about my investment decisions than ever before.",
        //     author: "Harsh P.",
        //     role: "Product Designer",
        //     img: "https://pagedone.io/asset/uploads/1696229994.png",
        // }, {
        //     id: 2,
        //     rating: 4.9,
        //     text: "Thanks to pagedone, I feel more informed and confident about my investment decisions than ever before.",
        //     author: "Harsh P.",
        //     role: "Product Designer",
        //     img: "https://pagedone.io/asset/uploads/1696229994.png",
        // }, {
        //     id: 2,
        //     rating: 4.9,
        //     text: "Thanks to pagedone, I feel more informed and confident about my investment decisions than ever before.",
        //     author: "Harsh P.",
        //     role: "Product Designer",
        //     img: "https://pagedone.io/asset/uploads/1696229994.png",
        // }, {
        //     id: 2,
        //     rating: 4.9,
        //     text: "Thanks to pagedone, I feel more informed and confident about my investment decisions than ever before.",
        //     author: "Harsh P.",
        //     role: "Product Designer",
        //     img: "https://pagedone.io/asset/uploads/1696229994.png",
        // },
        // Add more testimonials here
    ];

    const [currentIndex, setCurrentIndex] = useState(0);

    // Auto play the slider
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 2500); // Change slide every 2.5 seconds

        return () => clearInterval(interval);
    }, [testimonials.length]);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    return (
        <section className="py-24">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mb-16">
                    <span className="text-sm text-gray-500 font-medium text-center block mb-2">TESTIMONIAL</span>
                    <h2 className="text-4xl text-center font-bold text-gray-900">What our happy users say!</h2>
                </div>

                {/* Slider Wrapper */}


                <div className="relative overflow-hiddenborder-2 rounded-lg border-gray-700 hover:border-violet-700 flex transition-transform duration-500 ease-in-out"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {testimonials.map((testimonial, index) => (
                        <div className="grid grid-cols-8">
                            <div className=" col-start-2 col-end-4">
                                <div>

                                    <div class=" flex items-center justify-center ">
                                        <div class="bg-white p-5 rounded-2xl m-2 shadow-md max-w-md w-full">
                                            <div class="relative">
                                                <img src={step2} alt="Banner Profile" class="w-full rounded-t-lg h-32" />
                                                <img src={step4} alt="Profile Picture" class="absolute bottom-0 left-2/4 transform -translate-x-1/2 translate-y-1/2 w-24 h-24 rounded-full border-4 border-white" />
                                            </div>
                                            <div class="flex items-center mt-8">
                                                <h2 class="text-xl font-bold text-gray-800">{testimonial.author}</h2>
                                                {/* <button class=" px-2 py-1 rounded-full">
                                                <svg fill="#4d9aff" version="1.1" id="Capa_1"  xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="16px" viewBox="0 0 536.541 536.541" xml:space="preserve" stroke="#4d9aff">
                                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        <g>
                                                            <g>
                                                                <path d="M496.785,152.779c-3.305-25.085-16.549-51.934-38.826-74.205c-22.264-22.265-49.107-35.508-74.186-38.813 c-11.348-1.499-26.5-7.766-35.582-14.737C328.111,9.626,299.764,0,268.27,0s-59.841,9.626-79.921,25.024 c-9.082,6.965-24.235,13.238-35.582,14.737c-25.08,3.305-51.922,16.549-74.187,38.813c-22.277,22.271-35.521,49.119-38.825,74.205 c-1.493,11.347-7.766,26.494-14.731,35.57C9.621,208.422,0,236.776,0,268.27s9.621,59.847,25.024,79.921 c6.971,9.082,13.238,24.223,14.731,35.568c3.305,25.086,16.548,51.936,38.825,74.205c22.265,22.266,49.107,35.51,74.187,38.814 c11.347,1.498,26.5,7.771,35.582,14.736c20.073,15.398,48.421,25.025,79.921,25.025s59.841-9.627,79.921-25.025 c9.082-6.965,24.234-13.238,35.582-14.736c25.078-3.305,51.922-16.549,74.186-38.814c22.277-22.27,35.521-49.119,38.826-74.205 c1.492-11.346,7.766-26.492,14.73-35.568c15.404-20.074,25.025-48.422,25.025-79.921c0-31.494-9.621-59.848-25.025-79.921 C504.545,179.273,498.277,164.126,496.785,152.779z M439.256,180.43L246.477,373.209l-30.845,30.846 c-8.519,8.52-22.326,8.52-30.845,0l-30.845-30.846l-56.665-56.658c-8.519-8.52-8.519-22.326,0-30.846l30.845-30.844 c8.519-8.519,22.326-8.519,30.845,0l41.237,41.236L377.561,118.74c8.52-8.519,22.326-8.519,30.846,0l30.844,30.845 C447.775,158.104,447.775,171.917,439.256,180.43z"></path>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </button> */}
                                            </div>
                                            <p class="text-gray-700 mt-2">{testimonial.role}</p>
                                            <div class="flex items-center mt-4 space-x-4">
                                                <a href="#" class="text-blue-500 hover:underline"> Twitter </a>
                                                <a href="#" class="text-blue-500 hover:underline"> GitHub </a>
                                                <a href="#" class="text-blue-500 hover:underline"> LinkedIn </a>
                                            </div>
                                            <hr class="my-4 border-t border-gray-300" />
                                            <div class="flex justify-between text-gray-600 mx-2">
                                                <div class="text-center">
                                                    <span class="block font-bold text-lg">1.5k</span>
                                                    <span class="text-xs">Followers</span>
                                                </div>
                                                <div class="text-center">
                                                    <span class="block font-bold text-lg">120</span>
                                                    <span class="text-xs">Following</span>
                                                </div>
                                                <div class="text-center">
                                                    <span class="block font-bold text-lg">350</span>
                                                    <span class="text-xs">Posts</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" col-start-4 col-end-8 flex items-center justify-center">
                                <div>

                                    <h1 className="m-5 text-3xl">"Transformational Software Solutions"</h1>
                                    <h1 className="m-5 text-md">
                                        {testimonial.text}
                                    </h1>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>

                {/* <div
                        className=""

                    >
                        {testimonials.map((testimonial, index) => (
                            <div key={testimonial.id} className="min-w-full">
                                <div className="group bg-white border border-gray-300 rounded-xl p-6 transition-all duration-500 hover:border-indigo-600 hover:shadow-sm mx-auto w-full">
                                    <div className="flex items-center gap-2 text-amber-500 mb-7">
                                        <span className="text-base font-semibold text-indigo-600">{testimonial.rating}</span>
                                    </div>
                                    <p className="text-base text-gray-600 leading-6 pb-8 group-hover:text-gray-800">
                                        {testimonial.text}
                                    </p>
                                    <div className="flex items-center gap-5 border-t border-gray-200 pt-5">
                                        <img className="rounded-full h-10 w-10 object-cover" src={testimonial.img} alt="avatar" />
                                        <div>
                                            <h5 className="text-gray-900 font-medium mb-1">{testimonial.author}</h5>
                                            <span className="text-sm text-gray-500">{testimonial.role}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div> */}

                {/* Pagination */}
                <div className="flex justify-center mt-4 space-x-2">
                    {testimonials.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => goToSlide(index)}
                            className={`w-4 h-2 rounded-full ${currentIndex === index ? 'bg-indigo-600' : 'bg-gray-300'
                                }`}
                        ></button>
                    ))}
                </div>

            </div>
        </section>
    );
};





const Slider = () => {
    const slides = [
        {
            title: "App Development",
            description:
                "We develop high-performance mobile apps for iOS and Android, delivering exceptional user experiences tailored to your business needs.",
            image:
                { Img1 },
        },
        {
            title: "Web Development",
            description:
                "We create custom, high-performance web applications that are responsive, secure, and built to meet your business goals.",
            image: { Img2 },
        },
        {
            title: "UI-UX Design ",
            description:
                "We design intuitive, user-friendly interfaces that enhance user experience and ensure seamless interaction with your app or website.",
            image: { Img3 },
        },
        {
            title: "Digital Marketing ",
            description:
                "We craft data-driven digital marketing strategies to boost your brand’s visibility, drive engagement, and accelerate growth across online platforms.",
            image: { Img4 },
        },
        {
            title: "3D Web Site  ",
            description:
                "We build immersive 3D websites that deliver interactive and engaging experiences, bringing your brand to life in a dynamic, virtual environment.",
            image: { Img5 },
        },
    ];

    const [currentSlide, setCurrentSlide] = useState(0);

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    };

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    };

    return (
        <div className="my-10 mx-auto flex max-w-xl flex-col rounded-xl    py-10 px-4 text-center sm:px-10 lg:max-w-screen-lg lg:flex-row lg:text-left">
            <div className="relative mx-auto w-[15rem] max-w-full lg:mx-0 lg:w-[40rem]">
                <img
                    className="h-full w-full object-contain"
                    src={slides[currentSlide].image}
                    alt={slides[currentSlide].title}
                />
            </div>
            <div className="lg:pl-10">
                <h1 className="mt-8 text-4xl font-bold text-black md:text-5xl md:leading-snug">
                    {slides[currentSlide].title}
                </h1>
                <p className="mt-4 text-xl text-black">
                    {slides[currentSlide].description}
                </p>
                <div className="mt-8 flex flex-col rounded-md  bg-black ">
                    {/* Previous button */}
                    <button
                        onClick={prevSlide}
                        className="absolute  left-0  -translate-y-1/2 border border-gray-800 text-black p-2 rounded-full"
                    >
                        &#9664;
                    </button>
                    {/* Next button */}
                    <button
                        onClick={nextSlide}
                        className="absolute right-0  -translate-y-1/2 border border-gray-800 text-black p-2 rounded-full"
                    >
                        &#9654;
                    </button>
                </div>
            </div>
        </div>


    );
};


