// Radhe Radhe Ram Ram
import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { BrowserRouter,Route,Routes } from "react-router-dom";
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';


function App() {
  return (
    <>
    <Navbar/>
   <Home/>
   <Footer/>
  
    
    </>
  );
}

export default App;
