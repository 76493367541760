import React, { useState } from "react";
import Logo from './Img/logo.png'
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
export default function Navbar(params) {
    const [mobtoggle, setmobtoggle] = useState(false)
    const [comtoggle_Services, setcomtoggle_Services] = useState(false)
    const [comtoggle_Industries, setcomtoggle_Industries] = useState(false)

    const [opent, setopent] = useState(false)


    function mobOpenMenuBar(params) {
        setmobtoggle(true)
    }
    function mobhideSidemenu(params) {
        setmobtoggle(false)
    }

    function comOpenMenuBar_Services(params) {
        setcomtoggle_Services(true)
    }
    function comhideSidemenu_Services(params) {
        setcomtoggle_Services(false)
    }

    function comOpenMenuBar_Industries(params) {
        setcomtoggle_Industries(true)
    }
    function comhideSidemenu_Industries(params) {
        setcomtoggle_Industries(false)
    }


    return (
        <>
            {/* computer screen Servic */}
            <div className='hidden z-50  sm:block w-full h-full black-overlay fixed items-center'
                style={{
                    opacity: comtoggle_Services ? 1 : 0,
                    visibility: comtoggle_Services ? "visible" : 'hidden',
                    backgroundColor: "rgba(0, 0, 0, 0.7)"
                }}
                onClick={comhideSidemenu_Services}
            >
                <div onClick={(e) => { e.stopPropagation() }} className='bg-white   absolute duration-1000 w-screen h-screen'
                    style={{ top: comtoggle_Services ? "0%" : "-100%" }}
                >
                    <div className="text-4xl flex justify-between">
                        <div className="ms-8 justify-center text-center">
                            <h1>Tech Saksham</h1>
                        </div>

                        <div className="text-right">
                            <button onClick={comhideSidemenu_Services} className=" p-2 rounded-full text-4xl"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>
                    <div className=' '>
                        <div className="grid grid-cols-4">
                            <div>

                                <div className="">

                                </div>
                                <div className="flex items-center justify-center">
                                    <img src={Logo} className="w-32" alt="" />
                                </div>
                                <div className="text-center">
                                    <h1 className="text-2xl hover:border-b-2  hover:border-b-violet-600">Service</h1>
                                </div>

                            </div>
                            <div className="grid grid-cols-3 col-start-2 col-end-5 p-4 border border-gray-300">

                                <div className="m-2">
                                    <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                        <a href="#">
                                            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">App Development</h5>
                                        </a>
                                        <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Building custom apps tailored to your needs, offering seamless functionality and innovative user experiences."</p>
                                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                            See More ....
                                        </a>
                                    </div>
                                </div>

                                <div className="m-2">
                                    <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                        <a href="#">
                                            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Web Development</h5>
                                        </a>
                                        <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Crafting responsive, modern websites that engage users and drive growth with intuitive design and functionality."</p>
                                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                            See More ....

                                        </a>
                                    </div>
                                </div>


                                <div className="m-2">
                                    <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                        <a href="#">
                                            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Software Development</h5>
                                        </a>
                                        <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Developing scalable, efficient software solutions that streamline operations and enhance business performance."</p>
                                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                            See More ....

                                        </a>
                                    </div>
                                </div>


                                <div className="m-2">
                                    <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                        <a href="#">
                                            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Digital Marketing</h5>
                                        </a>
                                        <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Boosting your online presence with targeted digital marketing strategies to increase visibility, engagement, and conversions."</p>
                                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                            See More ....

                                        </a>
                                    </div>
                                </div>



                                <div className="m-2">
                                    <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                        <a href="#">
                                            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">UI-UX Design</h5>
                                        </a>
                                        <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Designing intuitive and visually engaging user interfaces that enhance user experience and drive satisfaction."</p>
                                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                            See More ....

                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* computer screen Industries */}
            <div className='hidden z-50  sm:block w-full h-full black-overlay fixed items-center'
                style={{
                    opacity: comtoggle_Industries ? 1 : 0,
                    visibility: comtoggle_Industries ? "visible" : 'hidden',
                    backgroundColor: "rgba(0, 0, 0, 0.7)"
                }}
                onClick={comhideSidemenu_Industries}
            >
                <div onClick={(e) => { e.stopPropagation() }} className='bg-white   absolute duration-1000 w-screen h-screen'
                    style={{ top: comtoggle_Industries ? "0%" : "-100%" }}
                >
                    <div className="text-4xl flex justify-between">
                        <div className="ms-8 justify-center text-center">
                            <h1>Tech Saksham</h1>
                        </div>

                        <div className="text-right">
                            <button onClick={comhideSidemenu_Industries} className=" p-2 rounded-full text-4xl"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>
                    <div className=' '>
                        <div className="grid grid-cols-4">
                            <div>

                                <div className="">

                                </div>
                                <div className="flex items-center justify-center">
                                    <img src={Logo} className="w-32" alt="" />
                                </div>
                                <div className="text-center">
                                    <h1 className="text-2xl hover:border-b-2  hover:border-b-violet-600">Industries</h1>
                                </div>

                            </div>
                            <div className="grid grid-cols-3 col-start-2 col-end-5 p-4 border border-gray-300">

                                <div className="m-2">
                                    <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                        <a href="#">
                                            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">App Development</h5>
                                        </a>
                                        <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Building custom apps tailored to your needs, offering seamless functionality and innovative user experiences."</p>
                                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                            See More ....
                                        </a>
                                    </div>
                                </div>

                                <div className="m-2">
                                    <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                        <a href="#">
                                            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Web Development</h5>
                                        </a>
                                        <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Crafting responsive, modern websites that engage users and drive growth with intuitive design and functionality."</p>
                                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                            See More ....

                                        </a>
                                    </div>
                                </div>


                                <div className="m-2">
                                    <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                        <a href="#">
                                            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Software Development</h5>
                                        </a>
                                        <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Developing scalable, efficient software solutions that streamline operations and enhance business performance."</p>
                                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                            See More ....

                                        </a>
                                    </div>
                                </div>


                                <div className="m-2">
                                    <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                        <a href="#">
                                            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">Digital Marketing</h5>
                                        </a>
                                        <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Boosting your online presence with targeted digital marketing strategies to increase visibility, engagement, and conversions."</p>
                                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                            See More ....

                                        </a>
                                    </div>
                                </div>



                                <div className="m-2">
                                    <div class="hover:shadow-2xl max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

                                        <a href="#">
                                            <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">UI-UX Design</h5>
                                        </a>
                                        <p class="mb-3 text-sm text-gray-500 dark:text-gray-400">"Designing intuitive and visually engaging user interfaces that enhance user experience and drive satisfaction."</p>
                                        <a href="#" class="inline-flex font-medium items-center text-blue-600 hover:underline">
                                            See More ....

                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* computer screen Industries */}
            <div className=' z-50  sm:block w-full h-full black-overlay fixed items-center'
                style={{
                    opacity: mobtoggle ? 1 : 0,
                    visibility: mobtoggle ? "visible" : 'hidden',
                    backgroundColor: "rgba(0, 0, 0, 0.7)"
                }}
                onClick={mobhideSidemenu}
            >
                <div onClick={(e) => { e.stopPropagation() }} className='bg-white   absolute duration-1000 w-screen h-[70%]'
                    style={{ top: mobtoggle ? "0%" : "-100%" }}
                >
                    <div className="text-4xl flex justify-between items-center">
                        <div className="ms-2 justify-center text-center">
                            <h1 className="text-xl">Tech Saksham</h1>
                        </div>

                        <div className="text-right">
                            <button onClick={mobhideSidemenu} className=" m-4 rounded-full text-xl"><i class="fa-solid fa-xmark"></i></button>
                        </div>
                    </div>
                    <div className=' '>
                        <div>
                            <div className="w-full border border-gray-700 " >
                                <button onClick={() => { setopent(true) }} className="border border-gray-800 ps-5 pe-5 pt-1 pb-1">Open</button>
                            </div>
                            <div onClick={(e) => { e.stopPropagation() }} className="w-full border border-gray-700 duration-1000" style={{  opacity: opent ? 1 : 0, visibility: opent ? "visible" : "hidden" , left: opent ? "0%" : "100%" }}>
                                <ul className="border border-gray-700">
                                    <Link to="/a"><li onClick={() => { setopent(false) }} >Home</li></Link>
                                    <Link to="/">
                                        <li onClick={() => { setopent(false) }} >Home</li>
                                    </Link>
                                    <li onClick={() => { setopent(false) }} >Home</li>
                                    <li onClick={() => { setopent(false) }} >Home</li>
                                    <li onClick={() => { setopent(false) }} >Home</li>
                                </ul>
                            </div>

                            <div>
                                <button>saksam</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className=" ps-3 pe-3 pt-4 pb-4 sm:pt-1 sm:pb-2 sm:ps-2 sm:pe-2 top-0 sticky z-40 bg-white shadow-2xlx">
                <div className="flex justify-between items-center">
                    <div className="hidden sm:block">
                        <img className="w-10" src={Logo} alt="" />
                    </div>
                    <div className=" sm:block">
                        <span className="text-2xl font-bold font-serif">Tech Saksham</span>
                    </div>
                    <div className="hidden   sm:block">
                        <ul className="flex ">
                            <li className="m-2 ms-4 me-4 hover:border-b-2 hover:border-b-violet-600 group relative flex w-fit items-center" >Home   <FiArrowRight className=" hover:block transition-transform group-hover:-rotate-45 group-active:-rotate-12" /></li>
                            <li className="m-2 ms-4 me-4 hover:border-b-2 hover:border-b-violet-600" onClick={comOpenMenuBar_Services} >Services</li>
                            <li className="m-2 ms-4 me-4 hover:border-b-2 hover:border-b-violet-600" onClick={comOpenMenuBar_Industries}>Industries</li>
                            <li className="m-2 ms-4 me-4 hover:border-b-2 hover:border-b-violet-600" >Portfolio </li>
                        </ul>
                    </div>
                    <div className="hidden sm:block">
                        <button className="border border-gray-300 hover:border-violet-900 ps-5 pe-5 rounded-lg">Contact Us</button>
                    </div>
                    <div className="sm:hidden">
                        <button className="text-xl hover: ps-5 pe-5 rounded-lg" onClick={mobOpenMenuBar}><i class="fa-solid fa-bars"></i></button>
                    </div>
                </div>
            </div>





        </>
    )
}

